<section class="hero_section">
  <div class="mx-1660 container">
    <div class="hero_inner_sec">
      <div class="left_sec">
        <div class="hero_desc fadeInEffect">
          <label class="subtitle3">{{ showroomDetails?.homepageSubtext }}</label>
          <h1 class="heading_1"
            [ngClass]="{'single_line' : lineCount == 1, 'double_line' : lineCount == 2, 'triple_line' : lineCount >= 3, 'visible': !!lineCount, 'invisible' : lineCount == 0}"
            id="propertyName">
            {{ showroomDetails?.showroomName }}
          </h1>
        </div>
      </div>
      <div class="right_sec">
        <figure class="hero_img_sec fadeInEffect">
          @if(!!showroomDetails?.image!){
          <img src="{{showroomDetails?.image!}}" srcset="
                  {{showroomDetails?.image! | imageSize: '_hero_phone' }} 800w,
                  {{showroomDetails?.image! | imageSize: '_hero_phone' }} 1200w,
                  {{showroomDetails?.image! | imageSize: '_hero_desk' }} 1920w,
                  {{showroomDetails?.image! }} 2560w
                " sizes="
                  (max-width: 800px) 800px,
                  (max-width: 1200px) 1200px,
                  (max-width: 1920px) 1920px,
                  2560px
                " alt="{{showroomDetails?.showroomName}}" />
          } @else {
          <img src="assets/images/hero_banner.jpg" alt="{{showroomDetails?.showroomName}}" />
          }
        </figure>
      </div>
    </div>
  </div>
</section>

<section class="product-carousel_wrapper">
  <div class="space60"></div>
  <div class="mx-1140 custom_product_mobile">
    <!-- Spaces list -->
    @for (space of homepageService.PROPERTY_DETAILS?.spaceList; track space.id) {
    @if(!!space.productCount) {
    @defer (on viewport) {
    <div class="product_carousel_container">
      <div class="carousel_header">
        <div class="left_sec">
          <app-title-display class="app_title" [title]="space.title"
            [productCount]="space.productCount"></app-title-display>
          <div class="view_all_btn dskp_btn">
            <a class="text_link1" routerLink="/{{footerService.PropertyId}}/{{space.subspaceName}}"
              (click)="amplitudeService.productCarouselViewAllClick(space.title)">View All</a>
            <span class="mobile_arrow"><img src="assets/images/right_arrow.svg" alt="" /> </span>
          </div>
        </div>
        <div class="right_sec arrow_btn">
          <button [disabled]="space.productCount <= 4" [ngClass]="{'slick-disabled' : space.productCount <= 4}"
            (click)="carousel.previousPage()" type="button" class="slick-prev slick-arrow" aria-disabled="true">
            <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.60531 0.826294L1.52637 6.90524L7.60531 12.9842" stroke="black" stroke-width="1.51974"
                stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
          </button>
          <button [disabled]="space.productCount <= 4" [ngClass]="{'slick-disabled' : space.productCount <= 4}"
            (click)="carousel.nextPage()" type="button" class="slick-next slick-arrow" aria-disabled="true">
            <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.39469 0.826294L7.47363 6.90524L1.39469 12.9842" stroke="#222222" stroke-width="1.51974"
                stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
          </button>
        </div>

      </div>
      <div class="ngx_carousel_wrapper">
        <app-carousel #carousel [items]="space.products" [idKey]="'id'" [carouselConfig]="crouselConfig" appWheelThrottle
        (throttledWheel)="space.productCount >  4 ? carouselScrollManually($event, carousel) : null" (slideChanged)="afterChange(space)">
          <ng-template #slide let-product let-index="index">
            <div class="product_img_wrapper" >

              <div class="slide product_card" data-aos="fade" data-aos-duration="600" data-aos-offset="0"
                [attr.data-aos-delay]="index * 100">
                <a appAffiliateUrl [product]="product" [isMobile]="isMobile" href="{{product.affiliateUrl ? (product.affiliateUrl | applyFn: getWebLink) : (product.productUrl | applyFn: getWebLink)}}"
                  id="{{product.showroomProductName}}" target="_blank" class="hover_link"  (click)="openProduct($event, space , product)"></a>
                <div class="product_img_wrapper">
                  @if(product.isFavourite) {
                  <span class="tag_ui fav_tag">
                    <i class="icon">
                      <svg width="8" height="7" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M5.93662 0.321899C4.81916 0.321899 4.19059 1.25312 4.19059 1.71872C4.19059 1.25312 3.56202 0.321899 2.44455 0.321899C1.32709 0.321899 1.04773 1.25312 1.04773 1.71872C1.04773 4.16317 4.19059 5.9092 4.19059 5.9092C4.19059 5.9092 7.33344 4.16317 7.33344 1.71872C7.33344 1.25312 7.05408 0.321899 5.93662 0.321899Z"
                          fill="white" stroke="white" stroke-width="0.609524" stroke-linecap="round"
                          stroke-linejoin="round" />
                      </svg>
                    </i>
                    Our Favorites
                  </span>
                  }

                  @if(product.isLocal) {
                  <span class="tag_ui local_tag">
                    <i class="icon">
                      <svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M5.69168 1.5464C5.48702 1.12471 5.1842 0.76356 4.81106 0.496141C4.43793 0.228723 4.00645 0.0636207 3.55629 0.0160138C3.35266 -0.00533793 3.14749 -0.00533793 2.94385 0.0160138C2.4937 0.0636519 2.06223 0.228749 1.68908 0.496136C1.31593 0.763523 1.01307 1.12462 0.808324 1.54626C0.550723 2.08424 0.451222 2.68985 0.522355 3.28682C0.593487 3.88378 0.83207 4.4454 1.20805 4.90093L3.0415 7.23039C3.06682 7.26257 3.09865 7.2885 3.1347 7.3063C3.17075 7.3241 3.21013 7.33333 3.25 7.33333C3.28988 7.33333 3.32925 7.3241 3.3653 7.3063C3.40136 7.2885 3.43319 7.26257 3.45851 7.23039L5.29195 4.90093C5.66793 4.44542 5.90651 3.88383 5.97765 3.28689C6.04878 2.68995 5.94928 2.08436 5.69168 1.5464ZM3.25 3.94857C3.03647 3.94857 2.82774 3.8824 2.6502 3.75843C2.47265 3.63445 2.33427 3.45825 2.25256 3.25209C2.17085 3.04594 2.14947 2.81909 2.19112 2.60023C2.23278 2.38138 2.33561 2.18035 2.48659 2.02256C2.63758 1.86477 2.82995 1.75732 3.03938 1.71379C3.24881 1.67026 3.46588 1.6926 3.66316 1.77799C3.86043 1.86338 4.02905 2.00799 4.14768 2.19353C4.26631 2.37906 4.32963 2.5972 4.32963 2.82034C4.32929 3.11946 4.21544 3.40622 4.01304 3.61773C3.81065 3.82924 3.53623 3.94822 3.25 3.94857Z"
                          fill="white" />
                      </svg>
                    </i>
                    Local
                  </span>
                  }
                  @defer (on idle) {
                  <img src="{{product.image!}}" srcset="
                                      {{product.image! }} 3840w,
                                      {{product.image! | imageSize: '_hero_phone' }} 1920w,
                                      {{product.image! | imageSize: '_hero_phone' }} 1200w,
                                      {{product.image! | imageSize: '_thumb' }} 800w
                                    " sizes="
                                    (min-width: 3840px) 3840px,
                                    (min-width: 1920px) 1920px,
                                    (min-width: 1200px) 1200px,
                                    800px
                                  " appPlaceholderImgDirective alt="{{ product.productName }}" width="100%">
                  }
                  @placeholder (minimum 300ms) {
                  <div class="product_skeleton">
                    <p-skeleton height="139px" width="139px"></p-skeleton>
                  </div>
                  }
                  <button class="shop_now_btn button_primary">Shop Now</button>
                </div>
                <div class="product_desc" style="max-width:285px; padding: 0">
                  @if(product.brandName) {
                  <label class="para1">{{ product.brandName }}</label>
                  }
                  <p class="para1">{{ product.productName }}</p>
                </div>
              </div>
            </div>
          </ng-template>
        </app-carousel>
      </div>
    </div>
    } @placeholder(minimum 300ms) {
    <div class="product_carousel_container">
      <ng-container *ngTemplateOutlet="carouselShimmmer"></ng-container>
    </div>
    }
    }
    }
    <!-- Spaces List ends here -->

    <!-- Our Favourite Starts here -->

    @if(showroomDetails?.ourFavoriteProductList?.productCount) {
    @defer (on viewport) {
    <div class="product_carousel_container">
      <div class="carousel_header">
        <div class="left_sec">
          <app-title-display class="app_title" [title]="'Our Favorites'"
            [productCount]="showroomDetails?.ourFavoriteProductList?.productCount!"></app-title-display>
          <div class="view_all_btn dskp_btn">
            <a class="text_link1" routerLink="/{{footerService.PropertyId}}/our-favorites"
              (click)="amplitudeService.productCarouselViewAllClick('our_favorites')">View All</a>
            <span class="mobile_arrow"><img src="assets/images/right_arrow.svg" alt="" /> </span>
          </div>
        </div>
        <div class="right_sec arrow_btn">
          <button [disabled]="showroomDetails?.ourFavoriteProductList?.productCount! <= 4"
            [ngClass]="{'slick-disabled' : showroomDetails?.ourFavoriteProductList?.productCount! <= 4}"
            (click)="carousel.previousPage()" type="button" class="slick-prev slick-arrow slick-disabled"
            aria-disabled="true">
            <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.60531 0.826294L1.52637 6.90524L7.60531 12.9842" stroke="black" stroke-width="1.51974"
                stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
          </button>
          <button [disabled]="showroomDetails?.ourFavoriteProductList?.productCount! <= 4"
            [ngClass]="{'slick-disabled' : showroomDetails?.ourFavoriteProductList?.productCount! <= 4}"
            (click)="carousel.nextPage()" type="button" class="slick-next slick-arrow slick-disabled"
            aria-disabled="true">
            <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.39469 0.826294L7.47363 6.90524L1.39469 12.9842" stroke="#222222" stroke-width="1.51974"
                stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
          </button>
        </div>
      </div>
      <div class="ngx_carousel_wrapper">
        <app-carousel #carousel [items]="showroomDetails?.ourFavoriteProductList?.products" [idKey]="'id'"
          [carouselConfig]="crouselConfig" (slideChanged)="afterChange(showroomDetails?.ourFavoriteProductList)">
          <ng-template #slide let-product let-index="index" appWheelThrottle
          (throttledWheel)="showroomDetails?.ourFavoriteProductList?.productCount! >  4 ? carouselScrollManually($event, carousel) : null">
            <div class="product_img_wrapper">
              <div class="slide product_card" data-aos="fade" data-aos-offset="0" data-aos-duration="600"
                [attr.data-aos-delay]="index * 100">
                <a appAffiliateUrl [product]="product" [isMobile]="isMobile" href="{{product.affiliateUrl ? (product.affiliateUrl | applyFn: getWebLink) : (product.productUrl | applyFn: getWebLink)}}"
                  id="{{product.showroomProductName}}" target="_blank" class="hover_link" (click)="openProduct($event, showroomDetails?.ourFavoriteProductList! , product)"></a>
                <div class="product_img_wrapper">
                  @defer (on idle) {
                  <img src="{{product.image!}}" srcset="
                                      {{product.image! }} 3840w,
                                      {{product.image! | imageSize: '_hero_phone' }} 1920w,
                                      {{product.image! | imageSize: '_hero_phone' }} 1200w,
                                      {{product.image! | imageSize: '_thumb' }} 800w
                                    " sizes="
                                    (min-width: 3840px) 3840px,
                                    (min-width: 1920px) 1920px,
                                    (min-width: 1200px) 1200px,
                                    800px
                                  " appPlaceholderImgDirective alt="{{ product.productName }}" width="100%">
                  }
                  @placeholder (minimum 300ms) {
                  <div class="product_skeleton">
                    <p-skeleton height="139px" width="139px"></p-skeleton>
                  </div>
                  }
                  <button class="shop_now_btn button_primary">Shop Now</button>
                </div>
                <div class="product_desc" style="max-width:285px; padding: 0">
                  @if(product.brandName) {
                  <label class="para1">{{ product.brandName }}</label>
                  }
                  <p class="para1">{{ product.productName }}</p>
                </div>
              </div>
            </div>
          </ng-template>
        </app-carousel>
      </div>
    </div>
    } @placeholder {
    <div class="product_carousel_container">
      <ng-container *ngTemplateOutlet="carouselShimmmer"></ng-container>
    </div>
    }
    }

    <!--our Favourite ends -->

    <!-- Local Items Starts here  -->

    @if(showroomDetails?.localItemsProductList?.productCount) {
    @defer (on viewport) {
    <div class="product_carousel_container">
      <div class="carousel_header">
        <div class="left_sec">
          <app-title-display class="app_title" [title]="'Local Items'"
            [productCount]="showroomDetails?.localItemsProductList?.productCount!"></app-title-display>
          <div class="view_all_btn dskp_btn">
            <a class="text_link1" routerLink="/{{footerService.PropertyId}}/local-items"
              (click)="amplitudeService.productCarouselViewAllClick('local_items')">View All</a>
            <span class="mobile_arrow"><img src="assets/images/right_arrow.svg" alt="" /> </span>
          </div>
        </div>
        <div class="right_sec arrow_btn">
          <button [disabled]="showroomDetails?.localItemsProductList?.productCount! <= 4"
            [ngClass]="{'slick-disabled' : showroomDetails?.localItemsProductList?.productCount! <= 4}"
            (click)="carousel.previousPage()"  type="button"
            class="slick-prev slick-arrow" aria-disabled="true">
            <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.60531 0.826294L1.52637 6.90524L7.60531 12.9842" stroke="black" stroke-width="1.51974"
                stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
          </button>
          <button [disabled]="showroomDetails?.localItemsProductList?.productCount! <= 4"
            [ngClass]="{'slick-disabled' : showroomDetails?.localItemsProductList?.productCount! <= 4}"
            (click)="carousel.nextPage()" type="button" class="slick-next slick-arrow" aria-disabled="true">
            <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.39469 0.826294L7.47363 6.90524L1.39469 12.9842" stroke="#222222" stroke-width="1.51974"
                stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
          </button>
        </div>
      </div>
      <div class="ngx_carousel_wrapper">
        <app-carousel #carousel [items]="showroomDetails?.localItemsProductList?.products" [idKey]="'id'"
          [carouselConfig]="crouselConfig" (slideChanged)="afterChange(showroomDetails?.localItemsProductList)" appWheelThrottle
          (throttledWheel)="showroomDetails?.localItemsProductList?.productCount! >  4 ? carouselScrollManually($event, carousel) : null">
          <ng-template #slide let-product let-index="index">
            <div class="product_img_wrapper">
              <div class="slide product_card" data-aos="fade" data-aos-offset="0" data-aos-duration="600"
                [attr.data-aos-delay]="index * 100">
                <a appAffiliateUrl [product]="product" [isMobile]="isMobile" href="{{product.affiliateUrl ? (product.affiliateUrl | applyFn: getWebLink) : (product.productUrl | applyFn: getWebLink)}}"
                  target="_blank" (click)="openProduct($event, showroomDetails?.localItemsProductList! , product)"
                  class="hover_link"></a>
                <div class="product_img_wrapper">
                  @defer (on idle) {
                  <img src="{{product.image!}}" srcset="
                                      {{product.image! }} 3840w,
                                      {{product.image! | imageSize: '_hero_phone' }} 1920w,
                                      {{product.image! | imageSize: '_hero_phone' }} 1200w,
                                      {{product.image! | imageSize: '_thumb' }} 800w
                                    " sizes="
                                    (min-width: 3840px) 3840px,
                                    (min-width: 1920px) 1920px,
                                    (min-width: 1200px) 1200px,
                                    800px
                                  " appPlaceholderImgDirective alt="{{ product.productName }}" width="100%">
                  }
                  @placeholder (minimum 300ms) {
                  <div class="product_skeleton">
                    <p-skeleton height="139px" width="139px"></p-skeleton>
                  </div>
                  }
                  <button class="shop_now_btn button_primary">Shop Now</button>
                </div>
                <div class="product_desc" style="max-width:285px; padding: 0">
                  @if(product.brandName) {
                  <label class="para1">{{ product.brandName }}</label>
                  }
                  <p class="para1">{{ product.productName }}</p>
                </div>
              </div>
            </div>
          </ng-template>
        </app-carousel>
      </div>
    </div>
    } @placeholder {
    <div class="product_carousel_container">
      <ng-container *ngTemplateOutlet="carouselShimmmer"></ng-container>
    </div>
    }
    }
    <!-- local items ends here -->

    <div class="btn_wrapper view_product_btn">
      <div class="btn_blk">
        <a class="button_primary" routerLink="/{{footerService.PropertyId}}/all-products" (click)="blurButton($event); amplitudeService.viewAllProductsClick('homepage')" tabindex="1">View All Products</a>
      </div>
      <p class="text_link2">When you purchase an item on this page, Minoan and the property may earn a commission.
        By clicking a product link, you agree to our <a target="_blank" href="https://minoan.com/terms">Terms</a> and <a target="_blank" href="https://minoan.com/privacy">Privacy Policy</a>.</p>
    </div>
  </div>
</section>


<!--Co Branding Section Start-->
<app-co-branding></app-co-branding>
<!--Co Branding Section End-->

<app-product-detail-view [productList]="mobileSliderProducts" [(productIndex)]="productIndex"
  [(showProductModal)]="showProductModal" previousURL=""></app-product-detail-view>

<app-external-link-popup [(externalLink)]="externalLinkPopup"></app-external-link-popup>


<ng-template #carouselShimmmer>
  <div class="carousel_header mb-1 mt-2">
    <div class="left_sec">
      <p-skeleton height="46px" width="200px"></p-skeleton>
    </div>
  </div>
  <div class="spacer d-flex visible">
    <p-skeleton height="139px" width="139px"></p-skeleton>
    <p-skeleton height="139px" width="139px"></p-skeleton>
    <p-skeleton height="139px" width="139px"></p-skeleton>
    <p-skeleton height="139px" width="139px"></p-skeleton>
  </div>
</ng-template>
