import {Component, Input, OnInit} from '@angular/core';
import {HomepageService} from "../../../services/homepage/homepage.service";
import {FooterService} from "../../../services/footer/footer.service";
import {AmplitudeService} from "../../../services/tracking/amplitude.service";
import {SpaceDetails} from "../../../interfaces/spaceDetails";
import {CarouselComponent} from '../carousel/carousel.component';


@Component({
  selector: 'app-discover-spaces',
  templateUrl: './discover-spaces.component.html',
  styleUrl: './discover-spaces.component.scss'
})
export class DiscoverSpacesComponent implements OnInit {

  @Input() currentSpaceId: string = ''
  @Input({required: true}) pageType: 'homepage' | 'local_items' | 'our_favorites' | 'space_page' | 'all_products' = 'space_page'
  carouselConfig = {
    breakpoints: {
      "(max-width: 80000px)": {
        slides: { perView: 3, spacing: 30 },
      },
      "(max-width: 1100px)": {
        slides: { perView: 3, spacing: 10 },
      },
      "(max-width: 991px)": {
        slides: { perView: 2.5, spacing: 10 },
      },
      "(max-width: 767px)": {
        slides: { perView: 2, spacing: 10},
      },
      "(max-width: 599px)": {
        slides: { perView: 1.6, spacing: 10},
      },
      "(max-width: 480px)": {
        slides: { perView: 1.58, spacing: 10},
      },
    },
    drag: false,
    loop: true,
    slides: {
      perView: 3,
      spacing: 12,
    }
  }

  spaceList: SpaceDetails[] = [];

  isSpaceImages: boolean | undefined = undefined;

  constructor(
    public homepageService: HomepageService,
    public footerService: FooterService,
    public amplitudeService: AmplitudeService,
  ) {
    const spaces = this.homepageService.PROPERTY_DETAILS?.spaceList.filter(each => !!each.productCount)!;
    this.isSpaceImages = spaces.some((each: SpaceDetails) => each.image != '');
  }

  /**
   *
   */
  ngOnInit() {
    // To filter out spaces with no products
    this.spaceList = this.homepageService.PROPERTY_DETAILS?.spaceList!.filter(each => !!each.productCount)!;
  }

  blurButton(event: any) {
    const element = event.target as HTMLAnchorElement;
    element.blur();
  }


  /**
   * Scroll carousel on wheel event
   * @param event
   * @param carouselRef
   */
  scrollCarousel(event: WheelEvent, carouselRef: CarouselComponent) {
    if(event.deltaX > 0) {
      carouselRef.nextPage();
    } else if(event.deltaX < 0) {
      carouselRef.previousPage();
    }
  }

  /**
   * Getter to disable slick buttons
   */
  get disableSlickButtons() {
    return this.spaceList.length <= 3;
  }
}
